<template>
  <div id="tier-3-certificate">
    <h1>Certificate Preview/Request</h1>
    <div class="tier-3-certificate-form">
      <div>
        <div class="submit-success">
          <p v-if="message">
            {{ message }}
          </p>
        </div>
        <div class="submit-errors">
          <p v-if="error">
            {{ error.message }}
          </p>
        </div>
        <transition name="fade">
          <div v-if="preview" class="preview-box">
            <div>
              <img v-if="!asPDF" :src="preview" alt="Preview" />
              <!-- <iframe v-if="asPDF" type="application/pdf" :src="preview"></iframe> -->
              <div v-if="asPDF">
                <p class="info-text">Please review downloaded PDF.</p>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col">
                  <es-btn color="teal" :full="true" @click="cancelPreview" :disabled="isBusy">Go Back</es-btn>
                </div>
                <div class="col">
                  <es-btn color="orange" :full="true" @click="confirmPDF" :disabled="isBusy">Confirm Certificate</es-btn>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <validation-observer v-if="!preview" ref="form" v-slot="{ invalid }">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col">
                  <validation-provider rules="required|uuid" name="User ID" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="txtUserID">User ID <fa icon="asterisk" /></label>
                      <input id="txtUserID" type="text" :disabled="isBusy" placeholder="Enter User ID" v-model="userID">
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="col">
                  <validation-provider rules="required|uuid" name="Sitting ID" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="txtSittingID">Sitting ID<fa icon="asterisk" /></label>
                      <input id="txtSittingID" type="text" :disabled="isBusy" placeholder="Enter Sitting ID" v-model="sittingID">
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <validation-provider rules="required" name="Name" v-slot="{ errors }">
                <div class="form-row" :class="{ 'error': errors.length > 0 }">
                  <label for="txtName">Name <fa icon="asterisk" /></label>
                  <input id="txtName" type="text" :disabled="isBusy" placeholder="Enter Name" v-model="name">
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <validation-provider rules="required|uuid" name="Product ID" v-slot="{ errors }">
                <div class="form-row" :class="{ 'error': errors.length > 0 }">
                  <label for="productID">Product ID <fa icon="asterisk" /></label>
                  <select id="productID" :disabled="isBusy" v-model="productID" required>
                    <option value="">Select Product</option>
                    <option v-for="(product, $index) in products" :key="`product_item_${$index}`" :value="product.id">{{ `${product.name}` }}</option>
                  </select>
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <div class="row">
                <div class="col">
                  <validation-provider rules="required|min:2|max:100" name="Address 1" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="address1">Address 1 <fa icon="asterisk" /></label>
                      <textarea rows="4" id="address1" type="text" :disabled="isBusy" placeholder="Enter Address 1" v-model="address1" required />
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="col">
                  <validation-provider rules="min:2|max:100" name="Address 2" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="address2">Address 2</label>
                      <textarea rows="4" id="address2" type="text" :disabled="isBusy" placeholder="Enter Address 2" v-model="address2"/>
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <validation-provider rules="required|min:2|max:100" name="City/Town" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="city">City/Town <fa icon="asterisk" /></label>
                      <input id="city" type="text" :disabled="isBusy" placeholder="Enter City/Town" v-model="city" required>
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div class="col">
                  <validation-provider rules="required|min:5|max:10" name="Zip/Postal Code" v-slot="{ errors }">
                    <div class="form-row" :class="{ 'error': errors.length > 0 }">
                      <label for="postcode">Zip/Postal Code <fa icon="asterisk" /></label>
                      <input id="postcode" type="text" :disabled="isBusy" placeholder="Enter Zip/Postal Code" v-model="postcode" required>
                      <p v-if="errors.length > 0" class="error">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
              </div>
              <validation-provider rules="required" name="Country" v-slot="{ errors }">
                <div class="form-row" :class="{ 'error': errors.length > 0 }">
                  <label for="country">Country <fa icon="asterisk" /></label>
                  <select id="country" :disabled="isBusy" v-model="selectedCountry" required>
                    <option value="">Select Country</option>
                    <option v-for="(countryItem, $index) in countryList" :key="`country_item_${$index}`" :value="countryItem.Iso2">{{ `${countryItem.Name} ${countryItem.Unicode}` }}</option>
                  </select>
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <validation-provider rules="required|numeric|no-all-zeros|min:8|max:12" name="Phone" v-slot="{ errors }">
                <div class="form-row" :class="{ 'error': errors.length > 0 }">
                  <label for="phone">Phone <fa icon="asterisk" /></label>
                  <div class="input-group">
                    <span>{{ countryCode }}</span>
                    <input id="phone" type="tel" :disabled="isBusy" placeholder="Enter Phone" v-model="phone" required>
                  </div>
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <validation-provider rules="image" name="Picture" v-slot="{ errors }">
                <div class="form-row" :class="{ 'error': errors.length > 0 }">
                  <input type="hidden" :value="image">
                  <label for="picture">Picture</label>
                  <input id="picture" ref="imageUploader" type="file" @change="updatePicutre" :disabled="isBusy"  accept="image/*" placeholder="Select Picture">
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </div>
              </validation-provider>
              <!-- <div class="form-row">
                <div class="switch">
                  <switches :disabled="isBusy" color="teal" v-model="asPDF" theme="es"></switches>
                  <span>Preview as 'PDF'</span>
                </div>
              </div> -->
              <p v-if="isBusy" class="busy">
                Please wait..
              </p>
              <div class="form-row">
                <button :disabled="isBusy || invalid" class="es-btn teal">Preview</button>
              </div>
            </form>
          </validation-observer>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import Switches from 'vue-switches'

export default {
  name: 'Tier3Certificate',
  data: () => ({
    // userID: '',
    // sittingID: '',
    // orderID: '',
    // productID: 'd0000f68-f2fa-4643-a626-d3594d93c9ad',
    // address1: '',
    // address2: '',
    // city: '',
    // postcode: '',
    // selectedCountry: '',
    // phone: '',
    userID: '',
    sittingID: '',
    name: '',
    productID: 'd0000f68-f2fa-4643-a626-d3594d93c9ad',
    address1: ' ',
    address2: '',
    city: '',
    postcode: '',
    selectedCountry: '',
    phone: '',
    picture: '',
    image: null,
    error: null,
    message: null,
    certFormat: 'pdf'
  }),
  computed: {
    ...mapGetters({
      isBusy: 'certificate/isCertBusy',
      countryList: 'country/sortedCountry',
      preview: 'certificate/preview',
      products: 'certificate/products'
    }),
    countryCode() {
      if (this.selectedCountry) {
        const country = this.countryList.find(country => country.Iso2 === this.selectedCountry)
        return country.Dial.indexOf('+') !== -1 ? country.Dial : `+${country.Dial}`
      }
      return '00'
    },
    asPDF: {
      get() {
        return this.certFormat === 'pdf'
      },
      set(value) {
        if (value) {
          this.certFormat = 'pdf'
        } else {
          this.certFormat = 'png'
        }
      }
    }
  },
  components: {
    // Switches
  },
  methods: {
    ...mapActions({
      requestPreview: 'certificate/requestPreview',
      cancelPreview: 'certificate/cancelPreview',
      fetchProducts: 'certificate/fetchProducts'
  }),
    async onSubmit() {
      try {
        this.message = null
        this.error = null
        let payload = {
          'user_id': this.userID,
          'sitting_id': this.sittingID,
          'product_id': this.productID,
          'name': this.name,
          'address_line_1': this.address1,
          'address_line_2': this.address2 || null,
          zipcode: this.postcode,
          city: this.city,
          country: this.selectedCountry,
          telephone: `${this.countryCode}${this.phone}`,
          'is_preview': true,
          'cert_format': this.certFormat
        }
        if (this.image) {
          payload = {
            ...payload,
            file: this.image
          }
        } else {
          payload = {
            ...payload,
            'skip_photo': true
          }
        }
        await this.requestPreview(payload)
      } catch(err) {
        this.error = err
      }
    },
    async confirmPDF() {
      try {
        this.message = null
        this.error = null

        let payload = {
          'user_id': this.userID,
          'sitting_id': this.sittingID,
          'name': this.name,
          'product_id': this.productID,
          'address_line_1': this.address1,
          'address_line_2': this.address2 || null,
          zipcode: this.postcode,
          city: this.city,
          country: this.selectedCountry,
          telephone: `${this.countryCode}${this.phone}`,
          'is_preview': false
        }
        if (this.image) {
          payload = {
            ...payload,
            file: this.image
          }
        } else {
          payload = {
            ...payload,
            'skip_photo': true
          }
        }

        await this.requestPreview(payload)

        this.userID = ''
        this.sittingID = ''
        this.name = ''
        this.orderID = ''
        this.address1 = ''
        this.address2 = ''
        this.postcode = ''
        this.city = ''
        this.selectedCountry = ''
        this.phone = ''
        this.picture = ''

        this.message = 'Request accepted.'
        this.cancelPreview()
        setTimeout(() => {
          if (this.$refs.form) {
            this.$refs.form.reset()
          }
        }, 100)
      } catch(err) {
        this.error = err
      }
    },
    updatePicutre() {
      if (this.$refs.imageUploader.files.length > 0) {
        this.image = this.$refs.imageUploader.files[0]
      } else {
        this.image = null
      }
    }
  },
  beforeMount() {
    const { uid, sid, pid } = this.$route.query
    this.userID = uid || this.userID
    this.sittingID = sid || this.sittingID
    this.productID = pid || this.productID

    this.fetchProducts()
  }
}
</script>
<style lang="scss" scoped>
#tier-3-certificate {
  margin-top: 1em;
  & h1 {
    text-align: center;
    margin-bottom: 1.5em;
  }
  & .tier-3-certificate-form {
    width: 700px;
    margin: auto;
    .submit-errors {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $cranberry;
        text-align: center;
        font-family: $headline;
      }
    }
    .submit-success {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $teal;
        text-align: center;
        font-family: $headline;
      }
    }
    p.busy {
      text-align: center;
      margin: 0;
      font-family: $headline;
    }
    & .form-row {
      margin-bottom: 1em;

      & label {
        display: block;
        font-size: 12px;
        cursor: pointer;

        & svg {
          color: $cranberry;
          width: 8px;
          vertical-align: top;
          margin-left: .25em;
        }
      }
      &.error {
        & input {
          border-color: $cranberry;
        }
      }
      & input, & select {
        @include input;
      }
      & select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        position: relative;

        // &::after {
        //   content: 'V';
        //   position: absolute;
        //   color: red;
        //   top: 1em;
        //   right: 1em;
        //   width: 2em;
        //   height: 2em;
        // }
      }
      & textarea {
        @include input;
        font-family: 'British Council Sans', sans-serif;
        resize: none;
      }
      & p.error {
        font-size: 14px;
        color: $cranberry;
        margin-top: .25em;
      }
      & button {
        width: 100%;
        margin-top: 2em;
      }
    }
  }
}

.row {
  & .col {
    flex: 1;
    margin: 0;
    padding: 8px;
    &:not(:last-child) {
      margin-right: .5em;
    }
  }
}
.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1em;
  margin-top: 0.25em;

  & span {
    font-size: 16px;
    line-height: 14px;
    margin-top: -2px;
  }

  & input {
    margin-top: 0 !important;
    margin-left: .5em;
    padding: 0 !important;
    border: none !important;
  }
}
.preview-box {
  padding-bottom: 2em;
  & iframe {
    width: 100%;
    height: 550px;
    margin-bottom: 1em;
  }

  & img {
    width: 100%;
    height: auto;
    vertical-align: text-bottom;
    margin-bottom: 1em;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
  }
}
.switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & label {
    margin-top: .1em;
  }
  & span {
    display: inline-block;
    margin-left: .5em;
    font-family: "BritishCouncilSansW15-Headline", sans-serif;
  }
  &:not(:last-child) {
    margin-right: 2em;
  }
}
</style>